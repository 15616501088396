import { useAuthStore } from "~/auth/stores/auth"
import { useAccessControl } from "~/layers/accessControl/composables/useAccessControl"

export default defineNuxtRouteMiddleware((to, from) => {
  const auth = useAuthStore()
  const user = computed(() => auth.user)
  if (user.value && Object.keys(user.value).length > 1) {
    const { userCan, superAdmin } = useAccessControl()
    if (!userCan(to?.meta?.permissions as string) && !superAdmin.value)
      return navigateTo("/restricted")
  }
})
